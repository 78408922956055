'use client'

import Link from 'next/link'

import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { TextInput } from '@astronautsid/wpe-astro-ui/components/atoms/TextInput'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@astronautsid/wpe-icons'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

type GlobalSearchBarPropsType = {
  boxProps?: BoxPropsType
  source?: string
}

const GlobalSearchBar = ({ boxProps, source }: GlobalSearchBarPropsType) => {
  const theme = useTheme()

  return (
    <Box {...boxProps}>
      <Link prefetch={false} href={source ? `/search?source=${source}` : '/search'}>
        <TextInput
          placeholder="Cari produk disini"
          InputProps={{
            id: 'search-product',
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            sx: {
              backgroundColor: theme.palette.bgColor.light,
              // eslint-disable-next-line
              '::placeholder': {
                color: theme.palette.textColor.placeholder,
              },
              cursor: 'pointer',
              input: {
                cursor: 'pointer',
              },
            },
            autoComplete: 'off',
            readOnly: true,
          }}
        />
      </Link>
    </Box>
  )
}

export default GlobalSearchBar
