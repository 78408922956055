import React from 'react'
import Link from 'next/link'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import Image, { type ImagePropsType } from 'components/Image'
import { ASSET_PREFIX } from 'config/constants'

type HomeCategoryCardPropType = {
  title: string
  image: string
  slug: string
  titleVisible: boolean
  boxPropsContainer?: BoxPropsType
  imageProps?: Omit<ImagePropsType, 'src' | 'alt' | 'fallbackImageSrc'>
  onClick?: () => void
}

const CategoryCard = ({
  title,
  image,
  slug,
  titleVisible = true,
  boxPropsContainer,
  imageProps,
  onClick,
}: HomeCategoryCardPropType) => (
  <Link prefetch={false} href={`/c/${slug}`} onClick={onClick}>
    <Box position="relative" borderRadius="8px" margin="0 auto" {...boxPropsContainer}>
      {titleVisible && (
        <Typography
          variant="caption-tinyBold"
          position="absolute"
          zIndex={1}
          textAlign="center"
          marginLeft="auto"
          marginRight="auto"
          left="0"
          right="0"
          top="12px"
          px="8px"
        >
          {title}
        </Typography>
      )}
      <Box sx={{ aspectRatio: 76 / 97 }}>
        <Image
          alt={title}
          src={image}
          fallbackImageSrc={`${ASSET_PREFIX}/img/placeholder-product.svg`}
          style={{
            borderRadius: '8px',
            objectFit: 'cover',
            width: '100%',
          }}
          fill
          {...imageProps}
        />
      </Box>
    </Box>
  </Link>
)

export default CategoryCard
