'use client'

import React, { useEffect, useMemo } from 'react'
import { log } from '@astronautsid/wpe-utils/helpers/datadog'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'
import { useQuery } from '@tanstack/react-query'
import useUserStore from 'store/user'

import Link from 'next/link'
import ProductCard from 'components/ProductCard'
import { mixpanelEventCategory } from 'utils/tracking/mixpanel/mixpanelConstants'
import { getDetailDynamicChannel } from '../../_utils/services/dynamicChannel'
import DynamicChannelSkeleton from './components/DynamicChannelSkeleton'

type DynamicChannelSlottingPropsType = {
  id: number
  boxProps?: BoxPropsType
}

const DynamicChannelSlotting = ({ id, boxProps }: DynamicChannelSlottingPropsType) => {
  const { customerAddress, token, isLoggedIn } = useUserStore((state) => state)

  const locationId = customerAddress.location_id || 0

  const {
    data: dynamicChannelResponse,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['dynamic channel detail', id],
    queryFn: () =>
      getDetailDynamicChannel(id, {
        location_id: locationId,
      }),
  })

  const dynamicChanneDetail = useMemo(
    () =>
      dynamicChannelResponse?.data.data || {
        title: '',
        products: [],
        category_id: [],
      },
    [dynamicChannelResponse?.data.data],
  )

  useEffect(() => {
    if (!isError) {
      return
    }

    log.info('Error get dynamioc channel slotting', {
      customInfo: {
        origin: 'code implementation',
        isLoggedIn,
        hasToken: !!token,
      },
    })
  }, [isError, isLoggedIn, token])

  if (isLoading) {
    return <DynamicChannelSkeleton />
  }

  if (!dynamicChanneDetail.products.length) {
    return null
  }

  return (
    <Box {...boxProps}>
      <Box marginTop="16px">
        <Box
          marginBottom="8px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
          px="16px"
        >
          <Typography component="h2" variant="headline-default">
            {dynamicChanneDetail.title}
          </Typography>
          <Link prefetch={false} href={`/d/${id}`}>
            <Typography variant="body-smallStrong" color={color.textColor.link} whiteSpace="nowrap">
              Lihat Semua
            </Typography>
          </Link>
        </Box>
        <Box display="flex" overflow="auto" gap="14px" className="box-hide-scroll" px="16px">
          {dynamicChanneDetail.products.map((product, indexProduct) => (
            <Box key={`dynamic-channel-product-${+indexProduct + 1}`}>
              <ProductCard
                boxPropsContainer={{
                  width: '110px',
                }}
                productData={{
                  astroCoin: product.astroCoin,
                  productDiscountDailyQuota: product.productDiscountDailyQuota,
                  productDiscountPercentage: product.productDiscountPercentage,
                  productDiscountPrice: product.productDiscountPrice,
                  productDiscountStock: product.productDiscountStock,
                  productFavorite: product.productFavorite,
                  productGrammationLabel: product.productGrammationLabel,
                  productID: product.productId,
                  productInventoryDiscountId: product.productInventoryDiscountId,
                  productName: product.productName,
                  productPrice: product.productPrice,
                  productStock: product.productStock,
                  productUrlImage: product.productUrlImage,
                  productVolume: product.productVolume,
                  productWeight: product.productWeight,
                  isEighteen: product.isTwentyOne,
                  productCategoryIds: dynamicChanneDetail.category_id,
                  productSlug: product.product_slug,
                  inventoryDiscountTierLabel: product?.inventory_discount_tier_label,
                  productLabels: product.product_labels,
                }}
                source="dynamicchannel"
                trackerData={{
                  eventData: {
                    eventLabel: dynamicChanneDetail.title,
                    item_list: `/${mixpanelEventCategory.RECOMMENDATION_PAGE} - product list -  recommendation ${dynamicChanneDetail.title}`,
                    items: [
                      {
                        item_name: product.productName,
                        item_id: String(product.productId),
                        price: Number(product.productPrice),
                        item_brand: '',
                        item_category: '',
                        item_variant: '',
                        quantity: product.productStock,
                        item_category5: product.productDiscountPrice || product.productPrice || '',
                        item_category4: `${product.productInventoryDiscountId || ''}`,
                        promotion_id: '',
                        item_list_id: `${indexProduct}`,
                        item_category3: `${product.productDiscountDailyQuota || ''}`,
                        item_list_name: '',
                        promotion_name: '',
                      },
                    ],
                  },
                  eventAction: {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    viewProduct: `view product card - recommendation`,
                    clickProduct: `click product card - recommendation`,
                    addToCart: `click add to cart on product card - recommendation`,
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default DynamicChannelSlotting
