'use client'

import Link from 'next/link'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'
import { Cart } from '@astronautsid/wpe-icons'

import useCart from 'hooks/useCart'

import CounterCart from './components/CounterCart'

const CartWidget = () => {
  const { cartData } = useCart({ silentError: true })

  const instantCart = cartData.find((item) => item.cart_type.toLowerCase() === 'instant')

  const totalCart = instantCart?.items.length || 0

  if (!totalCart) {
    return null
  }

  return (
    <Link prefetch={false} href="/cart">
      <Box
        position="relative"
        width={48}
        height={48}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="999px"
        bgcolor={color.bgColor.blue}
      >
        <Cart color={color.bgColor.light} type="solid" size={24} />
        <CounterCart counter={totalCart} />
      </Box>
    </Link>
  )
}

export default CartWidget
