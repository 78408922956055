import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@astronautsid+wpe-astro-ui@2.11.2_@emotion+react@11.13.0_@types+react@18.2.48_react@18.3.1__@_nfgddqj3cw2agefkvgpkl5xete/node_modules/@astronautsid/wpe-astro-ui/components/atoms/Box/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/@astronautsid+wpe-astro-ui@2.11.2_@emotion+react@11.13.0_@types+react@18.2.48_react@18.3.1__@_nfgddqj3cw2agefkvgpkl5xete/node_modules/@astronautsid/wpe-astro-ui/components/atoms/Typography/Typography.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["initDatadogLog","log","parsedStackFrame","setUserData"] */ "/app/node_modules/.pnpm/@astronautsid+wpe-utils@2.10.0_@datadog+browser-logs@4.50.1_axios@1.7.7_dayjs@1.11.12_error-s_zsy6ih4o7r7pnvjqx3jct3sqga/node_modules/@astronautsid/wpe-utils/helpers/datadog/datadog.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.2.48_react@18.3.1__@emotion+style_dvzq5fekijwnnercznnixcrj24/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.2.48_react@18.3.1__@emotion+style_dvzq5fekijwnnercznnixcrj24/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.23_@babel+core@7.26.0_@playwright+test@1.48.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.23_@babel+core@7.26.0_@playwright+test@1.48.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.23_@babel+core@7.26.0_@playwright+test@1.48.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(categoryandcatalogue)/_components/BottomSheetConfirmationAge/BottomSheetConfirmationAge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/CatalogueV2/CatalogueV2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/CategorySection/CategorySection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/CategorySection/ExpandedCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/DefaultHomepage/DefaultHomepage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/DynamicChannel/DynamicChannel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/DynamicChannel/DynamicChannelSlotting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/HomeBottomContent/HomeBottomContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderStyled"] */ "/app/src/app/(home)/_components/HomeHeader/style.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/NewHomeHeader/NewHomeHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/Placeholders/CatalogueV2Placeholder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/SearchBarFloating/SearchBarFloating.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/SlottingContainer/SlottingContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AppInstallBanner/AppInstallBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BottomNavigation/BottomNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CartWidget/CartWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GlobalSearchBar/GlobalSearchBar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Image/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Location/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/TopNav/TopNav.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/store/user/sessionStoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["clientErrorUnuthorizedHandler"] */ "/app/src/utils/unauthorizeHandling/client.ts");
