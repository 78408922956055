import { parseCookie } from 'utils/cookie'
import { COOKIE_DEVICE_ID } from 'utils/cookie/constant'

const isClient = () => typeof window !== 'undefined'

export const getDeviceIdFromCookie = (): string => {
  if (!isClient()) {
    throw new Error('This helper can only be called on client')
  }

  const cookies = parseCookie(document.cookie)

  return cookies[COOKIE_DEVICE_ID] || ''
}
