/* eslint-disable @typescript-eslint/naming-convention */
import {
  createAxiosInstance,
  type AxiosError,
  type InternalAxiosRequestConfig,
} from '@astronautsid/wpe-utils/helpers/axiosIntance'

import { getIronSession } from 'iron-session'

import { axiosErrorLoggerInterceptor } from 'config/axiosLogger'

import { SessionDataType, sessionOptions } from 'lib/session/config'
import { getHeadersConfig } from 'utils/helpers/getHeadersConfig'
import { getSessionStore } from 'store/user'
import { COOKIE_DEVICE_ID } from 'utils/cookie/constant'
import { getDeviceIdFromCookie } from 'utils/helpers/getDeviceIdFromCookie'
import { clientErrorUnuthorizedHandler } from 'utils/unauthorizeHandling/client'

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || ''

const isServer = typeof window === 'undefined'

const checkWebviewPage = () => {
  const searchParams = new URLSearchParams(window.location.search)

  return searchParams.get('webview') === 'true'
}

const handleResponseReject = (error: AxiosError) => {
  if (!isServer) {
    const isWebview = checkWebviewPage()
    // bypass webview
    if (!isWebview && error?.response?.status === 401) {
      clientErrorUnuthorizedHandler()

      return Promise.reject()
    }

    axiosErrorLoggerInterceptor(error)
  }

  return Promise.reject(error)
}

const getToken = async () => {
  if (isServer) {
    const { cookies } = await import('next/headers')
    const session = await getIronSession<SessionDataType>(cookies(), sessionOptions)
    return session.token
  }

  return getSessionStore().getState().token || null
}

const getDeviceId = async () => {
  if (isServer) {
    const { cookies } = await import('next/headers')
    return cookies().get(COOKIE_DEVICE_ID)?.value || ''
  }

  return getDeviceIdFromCookie()
}

const requestInterceptor = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  if (!config.headers.Authorization) {
    let token = await getToken()

    token = !config.url?.startsWith(process.env.NEXT_PUBLIC_API_BASE_URL as string) ? token : null

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${String(token)}` : undefined
  }

  if (!config.headers['X-DEVICE-ID']) {
    let deviceId = await getDeviceId()

    deviceId = !config.url?.startsWith(process.env.NEXT_PUBLIC_API_BASE_URL as string)
      ? deviceId
      : ''

    // eslint-disable-next-line no-param-reassign
    config.headers['X-DEVICE-ID'] = deviceId || undefined
  }

  return config
}

const axiosInstanceApiGateway = createAxiosInstance(
  {
    baseURL: API_BASE_URL,
    headers: getHeadersConfig(),
    maxRedirects: 0,
    timeout: 60000, // 1 minutes timeout since default timeout is disabled.
  },
  {
    interceptorResponseReject: handleResponseReject,
    interceptorRequest: requestInterceptor as never,
  },
)

export default axiosInstanceApiGateway
export type ConfigAxiosType = Parameters<(typeof axiosInstanceApiGateway)['Get']>['0']['config']
