'use client'

import { AUTH_CLEANUP_PATH } from './constants'

export const clientErrorUnuthorizedHandler = () => {
  const url = new URL(window.location.href)

  const currentFullpath = encodeURIComponent(`${url.pathname}${url.search}`)

  window.location.href = `${AUTH_CLEANUP_PATH}?redirectURL=${currentFullpath}`
}
