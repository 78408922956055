'use client'

import { PropsWithChildren } from 'react'

import useBrowserLog from './useBrowserLog'

const BrowserLog = ({ children }: PropsWithChildren) => {
  useBrowserLog()

  return children
}

export default BrowserLog
