/* eslint-disable @typescript-eslint/naming-convention */
import { APP_VERSION } from 'config/constants'

export const getHeadersConfig = (headers?: Record<string, unknown>) => ({
  'Access-Control-Allow-Origin':
    process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : undefined,
  'Access-Control-Max-Age': 7200,
  'X-API-VERSION': '1.9.11',
  'X-APP-VERSION': 'android-2.17.1',
  'X-DEVICE': 'web-customer',
  'X-DEVICE-VERSION': APP_VERSION,
  // required for BE API
  'X-DEVICE-ID': null,
  ...(headers || {}),
})
