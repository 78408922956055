import { APP_BASE_URL } from 'config/constants'
import { defaultSession, type SessionDataType } from 'lib/session/config'

const maskPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return ''

  // mask last 4 digits
  return `${phoneNumber.substring(0, phoneNumber.length - 4)}****`
}

export const getUserCtx = (userSession: SessionDataType) => ({
  usr: {
    userId: userSession.user?.id,
    phoneNumber: maskPhoneNumber(userSession?.user?.phone_number) || '',
    isLoggedIn: userSession.isLoggedIn,
    hasToken: !!userSession.token,
  },
})

export const getCommonErrorServerObject = ({
  error,
  otherAttrs = {},
  sessionData = defaultSession,
}: {
  error: Error
  otherAttrs?: Record<string, unknown>
  sessionData: SessionDataType
}) => {
  const errName = error?.name ? `${error?.name}: ` : ''
  const message = `${errName}${error?.message || ''}`

  return {
    level: 'error',
    message,
    error,
    customInfo: {
      origin: 'server-error',
      hostname: APP_BASE_URL || ``,
    },
    ...getUserCtx(sessionData),
    ...otherAttrs,
  }
}
